<template>
  <el-button type="primary" @click="add()">添加项目</el-button>
  <el-table
      :data="data.tableData"
      border
      style="width: 100%">
    <el-table-column
        prop="key"
        label="key">
    </el-table-column>
    <el-table-column
        prop="name"
        label="项目名称">
    </el-table-column>
    <el-table-column
        prop="create_user_name"
        label="创建人">
    </el-table-column>
    <el-table-column
        prop="password"
        label="访问密码">
    </el-table-column>
    <el-table-column
        fixed="right"
        label="操作"
        width="250">
      <template #default="scope">
        <el-button @click="toModel(scope.row)" type="text" size="small" v-show="scope.row.role_type > 0">模块</el-button>
        <el-button @click="edit(scope.row)" type="text" size="small" v-show="scope.row.role_type === 1">编辑</el-button>
        <el-button type="text" @click="projectUsers( scope.row.id)" size="small" v-show="scope.row.role_type === 1 || scope.row.role_type === 2">项目成员</el-button>
        <el-button type="text" @click="resetPassword(scope.row.id)" size="small" v-show="scope.row.role_type > 0">重置密码</el-button>
        <el-button type="text" @click="openProject( scope.row.path)" size="small">分享</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      layout="prev, pager, next"
      @current-change="currentChange"
      :current-page="data.pagination.currentPage"
      :page-size="data.pagination.pagerSize"
      :total="data.pagination.total">
  </el-pagination>
  <el-dialog v-model="data.dialogVisible.projectAdd" title="添加项目">
    <el-form ref="elForm" :model="data.formData" :rules="data.rules" size="medium" label-width="100px">
      <el-form-item label="项目索引" prop="key">
        <el-input v-model="data.formData.key" placeholder="请输入项目索引" :maxlength="32" show-word-limit clearable
                  :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="data.formData.name" placeholder="请输入名称项目项目名称" :maxlength="64" show-word-limit clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="handelConfirm">确定</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="data.dialogVisible.projectEdit" title="编辑项目">
    <el-form ref="editForm" :model="data.editData" :rules="data.rules" size="medium" label-width="100px">
      <el-form-item label="项目索引" prop="key">
        <el-input v-model="data.editData.key" placeholder="请输入项目索引" :maxlength="32" show-word-limit clearable
                  :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="data.editData.name" placeholder="请输入名称项目项目名称" :maxlength="64" show-word-limit clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="data.dialogVisible.projectEdit = false">取消</el-button>
      <el-button type="primary" @click="editConfirm">确定</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="data.dialogVisible.projectUsers" title="项目成员">
    <el-button type="primary" @click="userAdd()">添加成员</el-button>
    <el-table
        :data="data.projectUsers"
        border
        style="width: 100%">
      <el-table-column
          prop="nickname"
          label="用户名">
      </el-table-column>
      <el-table-column
          prop="type"
          label="类型"
          :formatter="formatter">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="200">
        <template #default="scope">
          <el-button @click="userEdit(scope.row)" type="text" size="small">编辑</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :icon="InfoFilled"
              icon-color="red"
              title="是否确认删除该成员?"
              @confirm="userDelete(scope.row.id)"
          >
            <template #reference>
              <el-button type="text" size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-button @click="data.dialogVisible.projectUsers = false">关闭</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="data.dialogVisible.projectUserAdd" :title="data.userData.title">
    <el-form ref="userForm" :model="data.userData" :rules="data.userRules" size="medium" label-width="100px">
      <el-form-item label="用户" prop="userId">
        <el-select v-model="data.userData.userId" :disabled="data.userData.projectUserId" filterable placeholder="请选择用户" :style="{width: '100%'}">
          <el-option
              v-for="item in data.users"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="data.userData.type" filterable placeholder="请选择类型" :style="{width: '100%'}">
          <el-option value=2 label="管理员"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="userClose">取消</el-button>
      <el-button type="primary" @click="handelUserConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>

import { reactive, getCurrentInstance } from "vue";
import {api} from "@/api";
import {router} from "@/router";
import {utils} from "@/utils";
export default {
  name: "project",
  setup() {
    const ctx = getCurrentInstance()

    const data = reactive({
      users: [],
      tableData: [],
      projectUsers: [],
      pagination: {
        total: 0,
        currentPage:1,
        pagerSize:15,
      },
      dialogVisible: {
        projectAdd: false,
        projectEdit: false,
        projectUsers: false,
        projectUserAdd: false
      },
      formData: {
        key: undefined,
        name: undefined,
      },
      editData: {
        projectId: undefined,
        key: undefined,
        name: undefined,
      },
      userData: {
        title: undefined,
        projectId: undefined,
        projectUserId: undefined,
        userId: undefined,
        type: undefined
      },
      rules: {
        key: [{
          required: true,
          message: '请输入项目索引',
          trigger: 'blur'
        }, {
          pattern: /^[A-Za-z0-9]*$/,
          message: '只能输入数字字母',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入名称项目项目名称',
          trigger: 'blur'
        }],
      },
      userRules: {
        userId: [{
          required: true,
          message: '请选择用户',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择类型',
          trigger: 'blur'
        }],
      },
    })
    const methods = {
      query: () => {
        api.project.lists({
          page: data.pagination.currentPage,
          limit: data.pagination.pagerSize
        }).then(res => {
          data.tableData = []
          data.pagination.total = res.data.total
          res.data.list.forEach(item => {
            data.tableData.push({
              id: item.id,
              key: item.key,
              name: item.name,
              create_user_name: item.create_user_name,
              path: item.path,
              password: item.password,
              role_type: item.role_type,
              created_at: item.created_at,
              updated_at: item.updated_at,
            })
          })
        })
      },
      openProject: (path) => {
        window.open(api.common.axurePath + path, '_blank');
      },
      currentChange: (page) => {
        data.pagination.currentPage = page
        methods.query()
      },
      add: () => {
        data.dialogVisible.projectAdd = true
      },
      edit: (row) => {
        data.editData.projectId = row.id
        data.editData.key = row.key
        data.editData.name = row.name
        data.dialogVisible.projectEdit = true
      },
      editConfirm: () => {
        ctx.refs['editForm'].validate(valid => {
          if (valid) {
            api.project.edit({
              projectId: Number(data.editData.projectId),
              key: data.editData.key,
              name: data.editData.name
            }).then(res => {
              if (res.code === 0) {
                utils.message.success("编辑成功")
                methods.query()
                data.dialogVisible.projectEdit = false
              }else{
                utils.message.error(res.msg)
              }
            })
          }
        })
      },
      toModel: (row) => {
        router.push({ path: `/project/module/${row.id}`})
      },
      close: () => {
        data.dialogVisible.projectAdd = false
        ctx.refs['elForm'].resetFields()
      },
      handelConfirm: () => {
        ctx.refs['elForm'].validate(valid => {
          if (valid) {
            api.project.add({
              key: data.formData.key,
              name: data.formData.name
            }).then(res => {
              if (res.code === 0) {
                methods.query()
                methods.close()
                utils.message.success('添加成功')
              } else {
                utils.message.error(res.msg)
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      },
      resetPassword: (projectId) => {
        api.project.resetPassword(projectId).then(res => {
          if (res.code === 0) {
            utils.message.success("重置成功")
            methods.query()
          } else {
            utils.message.error(res.msg)
          }
        })
      },
      formatter: (row) => {
        switch (row.type)  {
          case 1:
            return "创建人"
          case 2:
            return "管理员"
          case 3:
            return "用户"
        }
      },
      userLists: () => {
        api.user.lists().then(res => {
          data.users = res.data
        })
      },
      projectUsers: (projectId) => {
        api.project.projectUsers(projectId).then(res => {
          data.projectUsers = res.data
          data.userData.projectId = projectId
          data.dialogVisible.projectUsers = true
        })
      },
      userAdd: () => {
        data.userData.title = '添加项目成员'
        data.dialogVisible.projectUserAdd = true
      },
      userEdit: (row) => {
        data.userData.title = '编辑项目成员'
        data.userData.projectUserId = row.id
        data.userData.userId = row.user_id
        data.userData.type = String(row.type)
        data.dialogVisible.projectUserAdd = true
      },
      userClose: () => {
        data.userData.projectUserId = undefined
        data.userData.userId = undefined
        data.userData.userId = undefined
        data.userData.type = undefined
        data.dialogVisible.projectUserAdd = false
      },
      handelUserConfirm: () => {
        ctx.refs['userForm'].validate(valid => {
          if (valid) {
            if (data.userData.projectUserId === undefined) {
              api.project.projectUserAdd({
                projectId: data.userData.projectId,
                userId: data.userData.userId,
                type: Number(data.userData.type)
              }).then(res => {
                if (res.code === 0) {
                  methods.projectUsers(data.userData.projectId)
                  methods.userClose()
                  utils.message.success('添加成功')
                } else {
                  utils.message.error(res.msg)
                }
              })
            } else {
              api.project.projectUserEdit({
                projectUserId: data.userData.projectUserId,
                type: Number(data.userData.type)
              }).then(res => {
                if (res.code === 0) {
                  methods.projectUsers(data.userData.projectId)
                  methods.userClose()
                  utils.message.success('添加成功')
                } else {
                  utils.message.error(res.msg)
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      },
      userDelete: (projectUserId) => {
        api.project.projectUserDelete(projectUserId).then(res => {
          if (res.code === 0) {
            methods.projectUsers(data.userData.projectId)
            utils.message.success('添加成功')
          } else {
            utils.message.error(res.msg)
          }
        })
      }
    }
    methods.query()
    methods.userLists()
    return {
      data,
      ...methods
    };
  }
}
</script>

<style scoped>

</style>
